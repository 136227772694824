import React, { useState, useEffect, createContext } from "react";
import {initializeApp} from "firebase/app";
import {getAuth, User, onAuthStateChanged, Auth, getIdToken} from "firebase/auth";
import {getUserProfile} from './apiQueries';

const app = initializeApp({
  apiKey: "AIzaSyD846iy9GJLERqqhRSxDsoGGPS-Xp8lpq4",
  authDomain: "battlestation-builder.firebaseapp.com",
  projectId: "battlestation-builder",
  storageBucket: "battlestation-builder.appspot.com",
  messagingSenderId: "96131675920",
  appId: "1:96131675920:web:e17db49a04f1f7c434d795"
});

const auth = getAuth(app);

interface AuthType {
  user: User | null
  loading: boolean
  firebaseAuth: Auth
  userRoles: {isAdmin: boolean, isModerator: boolean}
  initialToken: string | undefined
}

const defaultAuth = {
  user: null,
  loading: true,
  firebaseAuth: auth,
  userRoles: {isAdmin: false, isModerator: false},
  initialToken: undefined
}
export const authContext = createContext<AuthType>(defaultAuth);

type Props = {
  children?: React.ReactNode
};
  
export function AuthProvider({children}: Props) {

  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isModerator, setIsModerator] = useState(false);
  const [initialToken, setInitialToken] = useState<string | undefined>(undefined);
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        getIdToken(user)
          .then(token => {

            setInitialToken(token);
            getUserProfile(user.uid, token)
              .then(profile => {
                setUser(user);
                setIsAdmin(profile.is_admin);
                setIsModerator(profile.is_moderator);
                setLoading(false);
              })
              .catch(error => {
                setLoading(false);
                console.log(error)
              })
          })
          .catch(error => {
            setLoading(false);
              console.log(error)
          })
      } 
      else {
        setUser(null);
        setLoading(false);
      }
    });
    return () => unsubscribe();
  }, []);

  return <authContext.Provider value={{user: user, initialToken: initialToken, loading: loading, firebaseAuth: auth, userRoles: {isAdmin: isAdmin, isModerator: isModerator}}}>{children}</authContext.Provider>;
}